/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-11 15:15:35
 * @LastEditors: zhangWei
 * @LastEditTime: 2024-03-16 22:14:54
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import LayoutMain from '@/views/LayoutMain'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const demoRouter = [
  // 首页
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  // 企业文化
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/layout/aboutUs/index.vue')
  },
  // 产品中心
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/layout/product/index.vue')
  },
  // 产品详情
  {
    path: '/commodityDetails',
    name: 'commodityDetails',
    component: () => import('@/views/layout/commodityDetails/index.vue')
  },
  // 业务布局
  {
    path: '/businessLayout',
    name: 'businessLayout',
    component: () => import('@/views/layout/businessLayout/index.vue')
  },
  // 市场资讯
  {
    path: '/marketInformation',
    name: 'marketInformation',
    component: () => import('@/views/layout/marketInformation/index.vue')
  }
  ,
  // 社会责任
  {
    path: '/socialResponsibility',
    name: 'socialResponsibility',
    component: () => import('@/views/layout/socialResponsibility/index.vue')
  }
  // InformationDetails
  ,
  //资讯详情
  {
    path: '/InformationDetails',
    name: 'InformationDetails',
    component: () => import('@/views/layout/InformationDetails/index.vue')
  }
  ,
  //联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/layout/contactUs/index.vue')
  }
]

export const constantRouterMap = [
  {
    path: '/',
    // redirect: '/demo'
    redirect: '/home'
  },
  ...demoRouter
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRouterMap
  })


const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}


export default router