/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2024-02-18 16:49:20
 * @LastEditors: syc
 * @LastEditTime: 2024-02-18 17:06:25
 */
const state = {
    Chenglish: ''
}

const mutations = {
    SET_CHENG_LISH: (state, value) => {
        state.Chenglish = value
    }
}

const actions = {
    setChenglish({ commit }, value) {
        commit('SET_CHENG_LISH', value)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

