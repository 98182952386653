/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-09 16:00:14
 * @LastEditors: zhangWei
 * @LastEditTime: 2024-04-14 22:53:56
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store'

import router from './router'
import ElementUI from 'element-ui'
import 'amfe-flexible'

// 在 main.js 中引入
import '@/assets/css/font.css'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/utils/rem.js'
import "./utils/index"
// import { initRem } from './utils/rem'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.clearStore === true) {
    store.commit('resetState') // 调用Vuex中的mutation函数重置state
  }

  next()
})

Vue.use(ElementUI)
// 事件总线
Vue.prototype.$EventBus = new Vue()
// 中英文转换
import i18n from '@/i18n/index'

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = '稻丰农业';
  }
})

// const rootValue = 37.5
// const rootWidth = 375
// const deviceWidth = document.documentElement.clientWidth; // 用户的设备屏幕宽度
// document.documentElement.style.fontSize = (deviceWidth * rootValue / rootWidth) + 'px';
// initRem();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
