<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-09 16:00:14
 * @LastEditors: syc
 * @LastEditTime: 2023-11-12 17:54:30
-->
<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
</style>
