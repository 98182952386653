/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-11 01:10:10
 * @LastEditors: zhangWei
 * @LastEditTime: 2024-03-23 15:48:51
 */
import enLocale from 'element-ui/lib/locale/lang/en'
export default {
  "lang": "English",
  // 菜单
  "menu": {
    "index": "home",//媒体
    "enterprise": "EnterpriseCulture",//数据
    "product": "ProductCenter",//对接
    "business": "BusinessTayout",//当前操作账号
    "information": "MarketinFormation",//当前操作账号
    "joinUs": "SocialResponsibility",//当前操作账号
    "contactUs": "ContactUs"
  },
  "logo": {
    "title": 'RiceAgriculture'
  },
  "title": {
    "title1": 'About Us',
    "title2": 'Product Center',
    "title3": 'Market Information',
    "title4": 'Market information Inafeng Agriculture global agricultural products shipping information is updated in real time',
    "title5": 'Sphere Of Business',
    "title6": 'View More>>',
    "title7": 'Cultivate',
    "title8": 'Storage',
    "title9": 'Processing',
    "title10": 'Market',
    "title11": 'Rice farming. Copyright Address: 7th Floor, Anqiu Nonggu Building, Anqiu City, Weifang, Shandong, China',
    "title12": 'Column Navigation',
    "title13": 'Cooperative Inquiry',
    "title14": 'ContactUs',
    "title15": 'Have any cooperation needs, welcome to leave your contact information in this message box, we will reply you as soon as possible.',
    "title16": 'Please leave your information and requirements here, and we will contact you as soon as possible',
    "title17": 'Address Information',
    "title18": 'Address: 7th Floor, Anqiu Nonggu Building, Anqiu, Weifang, Shandong, China',
    "title19": 'Cellphone',
    "title20": 'Phone',
    "title21": 'Email',
    "title22": 'Facsimile',
    "title23": 'Create Now',
    "title24": 'Company Address',
    "title25": 'Company Tel',
  },
  "introduction": {
    "content": 'Shandong Daofeng Agricultural Development Co, Ltd,Headquartered in Agricultur Building,Angiu City,Weifang City,ShandongProvince,China, is a joint-stock enterprise specializing in the production, supply and marketing of agricultural products.Since its establishment, the company has always adhered to the corporate tenet of "close source, complete range, excelent products, andgood service", relying on a large number of planting bases and suppliers, based on processing plants and warehousing centers, and extendingproduct services and information services, actively promoting the large- scale development of planting bases and warehousing processing inescurrentl.it has nearlvy 100 hectares of its own and cooperative lanting bases Nearlv 10000 souare meters of orocessing factories and over 1000tons of storage and refrigerated warehouses.n addition, the company also has its own agricultural product information platform, which collect.and summarizes the atest agricultural product market transaction information in a timely manner, providing timely and fresh information services for partners.The company s main products include preservation and deep processing Preservation products mainly include pear,apple,grape,or-ange,lemon,ginger, taro,garlic,onion,carrot,potato,scallion,et. Deep processing products mainly include dehydrated garlic, dehydrated gingerdehydrated scallions, sweet chil, red and green peppers, tomato powder, barley grass powder,etc. The company has multiple procesing factories'
  },
  ...enLocale
}