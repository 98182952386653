/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-11 01:10:31
 * @LastEditors: zhangWei
 * @LastEditTime: 2024-03-23 14:56:43
 */
import zhLocale from 'element-ui/lib/locale/lang/en'
export default {
  "lang": "中文",
  // 菜单
  "menu": {
    "index": "首页",//媒体
    "enterprise": "企业文化",//数据
    "product": "产品中心",//对接
    "business": "业务布局",//当前操作账号
    "information": "市场资讯",//当前操作账号
    "joinUs": "社会责任",//当前操作账号
    "contactUs": "联系我们"
  },
  // 子菜单
  "subset": {
    "profile": "企业简介"
  },
  "logo": {
    "title": '稻丰农业'
  },
  "title": {
    "title1": '关于我们',
    "title2": '产品中心',
    "title3": '市场资讯',
    "title4": '稻丰农业全球农产品发货信息实时更新',
    "title5": '业务范围',
    "title6": '查看更多>>',
    "title7": '种植',
    "title8": '存储',
    "title9": '加工',
    "title10": '销售',
    "title11": '稻丰农业。版权所有 地址：中国·山东省潍坊市安丘市安丘农谷大厦7楼',
    "title12": '栏目导航',
    "title13": '合作垂询',
    "title14": '联系我们',
    "title15": '有任何合作需求，欢迎在此留言井留下您的联系方式，我们将尽快回复您。',
    "title16": '请在这里留下您的信息与需求，我们会尽快联系您~',
    "title17": '地址信息',
    "title18": '地址: 中国·山东省潍坊市安丘市安丘农谷大厦7楼~',
    "title19": '手机',
    "title20": '电话',
    "title21": '电子邮件',
    "title22": '传真',
    "title23": '立即创建',
    "title24": '公司地址',
    "title25": '公司电话',
  },
  "introduction": {
    "content": '山东稻丰农业发展有限公司, 总部位于山东省潍坊市安丘农谷大厦, 是一家专门从事农产品产、供、销全链条服务的股份制企业。公司自成立以来, 始终秉承“源头近、品类全、产品优、服务好”的企业宗旨, 以广大种植基地与供应商为依托，以加工厂与仓储中心为基础，以产品服务与信息服务为延伸, 积极推进种植基地、仓储加工流水线的规模化发展，目前已拥有近100公顷的自有及合作种植基地、近10000m2的加工工厂及10万吨以上的仓储、冷藏仓库。此外，公司还有自己的农产品信息平台, 及时收集汇总最新农产品市场交易信息, 为合作伙伴提供及时新鲜的资讯服务。公司主打产品包含保鲜类与深加工类，保鲜产品主要有: 梨、苹果、葡萄、橘子、橙子、柠檬、大姜、芋头大蒜、洋葱、胡萝卜、土豆、大葱等, 深加工产品主要有: 脱水蒜、脱水姜、脱水葱、甜辣椒、红青椒、番茄粉、大麦草粉等。公司在潍坊安丘、烟台栖霞、济宁金乡、聊城冠县、河北泊头等地有多个加工工厂与存储冷库。公司拥有进出口资质以及多年的进出口经验, 客户遍及欧美、日本、东南亚、中东等50多个国家地区公司旗下有安丘旺稼果蔬有限公司、海南稻丰国际贸易有限公司、安丘市旺沙源农牧专业合作社等多家分公司及合作社。'
  },
  ...zhLocale
}