/*
 * @Description: 
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2023-11-11 01:10:38
 * @LastEditors: syc
 * @LastEditTime: 2023-11-11 01:11:41
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zh from './zh'
import en from './en'
Vue.use(VueI18n)
const messages = {
  en: {
    ...en,
  },
  zh: {
    ...zh,
  }
};
const i18n = new VueI18n({
  locale: localStorage.lang || 'zh',
  messages,
});
locale.i18n((key, value) => i18n.t(key, value))
export default i18n;